/*******************************************************************************
 * CONFIGURATION
 */
@import "@mod-utwente_design/scss/config-colors.css";
/*

Design Contentwidth Sidemargin TOTAL  Column Gutter MenuOpen HP-columns
1600     Fixed 1110         15  1140  65     30     TRUE     12
1440     Fixed 1110         15  1140  65     30     TRUE     12
1280     Fixed  930         15   960  50     30     TRUE     12
1080     Fixed  930         15   960  50     30     FALSE    12
 750     Fluid  690         15   720  30     30     FALSE    10 less cols so we can switch to mobile style without huge blocks)
 320     Fluid    X         20     X  30/X   20     FALSE    6  ()

*/
/*******************************************************************************
 * MIXINS
 */
/*
Use the have an element span over an X amount of design columns
IMPORTANT NOTES:
- Only use this within 12 column container!!
-
*/
/*
NOTE: unlike .main__contents--fullwidth we use the full viewport width (no padding)
*/
@keyframes blink-animation {
  50% {
    opacity: 0;
  }
}
/* NOTE: To use
         - The container must be positioned
         - The container cannot be inline, make it block so the effect can use 100% width
         - Content within must be positioned so the effect won't be drawn over the content
*/
/* CSS which only applies when used as file widget
   (not when used inline within the page content)
*/
.widget-brochurerequestform .widget__rawhtml {
  padding: 0 25px 15px;
}

.widget-brochurerequestform .widget__rawhtml__contents {
  border-top: 1px solid #dcddde;
}

.emailbrochureform-success h3 {
  margin-top: 0;
}

.widget-brochurerequestform_inline .foldablecontent__foldcontainer {
  background-color: #f7f8f8;
}
.widget-brochurerequestform_inline .foldablecontent__text {
  position: relative;
}
.widget-brochurerequestform_inline .foldablecontent__title {
  border-top: 0 none;
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}
.widget-brochurerequestform_inline .foldablecontent__title::before, .widget-brochurerequestform_inline .foldablecontent__title::after {
  display: none;
}
.widget-brochurerequestform_inline .foldablecontent__title .icon {
  font-size: 48px;
  flex: none;
}
.widget-brochurerequestform_inline .foldablecontent__title .title {
  flex: 1 1 auto;
  font-size: 18px;
  line-height: 140%;
  padding-left: 20px;
  padding-right: 20px;
}
.widget-brochurerequestform_inline .foldablecontent__title .title small {
  font-size: 100%;
  font-weight: 300;
  color: #5A5E62;
  text-decoration: underline;
}
.widget-brochurerequestform_inline .foldablecontent__text {
  margin-right: 20px;
  margin-left: 20px;
}
.widget-brochurerequestform_inline .foldtoggle {
  flex: none;
  cursor: pointer;
}
.widget-brochurerequestform_inline .foldtoggle > span {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  padding: 0;
  justify-content: center;
  align-items: center;
}
.widget-brochurerequestform_inline .foldtoggle__open {
  display: flex;
  position: relative;
}
.widget-brochurerequestform_inline .foldtoggle__open::before {
  content: "";
  display: inline-block;
  width: 2px;
  height: 12px;
  background-color: #fff;
}
.widget-brochurerequestform_inline .foldtoggle__open::after {
  content: "";
  transform: rotate(45deg);
  position: absolute;
  top: 13px;
  left: 12px;
  width: 8px;
  height: 8px;
  border-bottom: 2px solid #fff;
  border-right: 2px solid #fff;
}
.widget-brochurerequestform_inline .foldtoggle__close {
  display: none;
  background-color: #AAACAE;
}
.widget-brochurerequestform_inline .foldtoggle__close::before {
  content: "";
  display: inline-block;
  width: 12px;
  height: 2px;
  background-color: #fff;
}
.widget-brochurerequestform_inline .foldablecontent--open .foldtoggle__open {
  display: none;
}
.widget-brochurerequestform_inline .foldablecontent--open .foldtoggle__close {
  display: flex;
}
.widget-brochurerequestform_inline .wh-form__buttongroup .wh-form__button + .wh-form__button {
  margin-left: 15px;
}
@media (max-width: 750px) {
  .widget-brochurerequestform_inline .wh-form__buttongroup .wh-form__button + .wh-form__button {
    margin-left: 0;
    margin-top: 15px;
  }
}

.widget-brochurerequestform .wh-form__buttongroup .wh-form__button + .wh-form__button,
.widget-brochurerequestform_inline .wh-form__buttongroup .wh-form__button + .wh-form__button {
  margin-top: 15px;
}
.widget-brochurerequestform .requestform__download,
.widget-brochurerequestform_inline .requestform__download {
  margin: 20px 0;
}
.widget-brochurerequestform .requestform--aftersubmission ~ .downloadpage .requestform__download .wh-form__button.download,
.widget-brochurerequestform_inline .requestform--aftersubmission ~ .downloadpage .requestform__download .wh-form__button.download {
  display: none;
}
.widget-brochurerequestform .brochurerequestform__htmlbrochure,
.widget-brochurerequestform_inline .brochurerequestform__htmlbrochure {
  margin: 20px 0;
}
.widget-brochurerequestform .emailbrochureform-success,
.widget-brochurerequestform_inline .emailbrochureform-success {
  display: none;
}
.widget-brochurerequestform .wh-form__buttongroup,
.widget-brochurerequestform_inline .wh-form__buttongroup {
  margin-bottom: 10px;
}
.widget-brochurerequestform .wh-form__page,
.widget-brochurerequestform_inline .wh-form__page {
  margin-top: 0;
}

.widget-brochurerequestform_inline {
  /*
  foldablecontent__text has 10px v padding
  foldablecontent__text--rte has 30px v padding
  for inline brochure widget we want 50px v padding
  */
}
.widget-brochurerequestform_inline .wh-form__label {
  text-align: right;
}
.widget-brochurerequestform_inline [data-wh-form-group-for="permission.checkbox"] {
  margin-left: 220px;
  margin-top: 18px;
}
@media (max-width: 750px) {
  .widget-brochurerequestform_inline [data-wh-form-group-for="permission.checkbox"] {
    margin-left: 0;
  }
}
.widget-brochurerequestform_inline .wh-form__buttongroup.wh-form__buttongroup {
  text-align: left;
  margin-top: 30px;
}
.widget-brochurerequestform_inline .requestform__otherbrochure ~ .wh-form__buttongroup {
  padding-left: 0;
}
.widget-brochurerequestform_inline .foldablecontent__text {
  padding-top: 50px;
  padding-bottom: 50px;
  border-bottom: 1px solid #dcddde;
}

.widget-brochurerequestform .aftersubmission, .widget-brochurerequestform .afterlanding {
  padding-top: 10px;
}

.widget-brochurerequestform .imnot {
  margin-top: 10px;
}